:host {
  /* Core variables */
  --transition-duration: 0.15s;
  --timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --transition-props: color var(--transition-duration) var(--timing-function),
    background-color var(--transition-duration) var(--timing-function),
    border-width var(--transition-duration) var(--timing-function),
    border-color var(--transition-duration) var(--timing-function),
    text-decoration-color var(--transition-duration) var(--timing-function),
    fill var(--transition-duration) var(--timing-function),
    stroke var(--transition-duration) var(--timing-function),
    opacity var(--transition-duration) var(--timing-function),
    box-shadow var(--transition-duration) var(--timing-function),
    transform var(--transition-duration) var(--timing-function),
    filter var(--transition-duration) var(--timing-function),
    backdrop-filter var(--transition-duration) var(--timing-function);

  /* Colors */
  --gray-50: #f9fafb;
  --gray-200: rgb(214 211 209);
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --red-100: rgb(254 226 226);
  --red-500: rgb(239 68 68);
  --red-700: rgb(185 28 28);
  --yellow-50: rgb(255, 251, 235);
  --yellow-400: rgb(251, 191, 36);
  --green-500: rgb(14 195 80);

  /* Base styles */
  border: 2px dashed var(--gray-200);
  border-radius: 0.75rem;
  margin-top: 1rem;
  padding: 20px;
  color: var(--gray-500);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 4rem;
  font-size: 0.875rem;
  transition: var(--transition-props);
}

/* Error states */
:host([status="error"]) .fail-message {
  color: var(--red-700);
}

:host([status="fail"]),
:host([status="invalid"]) {
  background-color: var(--red-100);
  border-color: var(--red-500);
  color: var(--red-700);

  .message {
    border-top-color: rgb(185 28 28);
  }
}

:host([status="warning"]) {
  cursor: default;
  background-color: var(--yellow-50);
  border: 1px solid var(--yellow-400);
}

:host([failedAndSaved]) {
  cursor: default;
  border: 1px solid var(--red-500);
}

/* Progress states */
:host([status="uploading"]),
:host([status="validating"]),
:host([status="incomplete"]),
:host([status="valid"]) {
  cursor: default;
  border: 1px solid var(--gray-200);
}

/* Hover states */
:host(:not([status]):hover),
:host([status=""]:hover),
:host([status="fail"]:not([failedAndSaved]):hover),
:host([status="invalid"]:hover),
:host([status="error"]:hover),
:host(:not([status="incomplete"]).dragover) {
  background-color: var(--gray-50);
  border-color: var(--gray-200);
  color: var(--gray-600);
}

/* Incomplete state special handling for dropzone */
:host([status="incomplete"]) {
  .prompt-container {
    transition: var(--transition-props);
    cursor: pointer;
    border: 2px dashed var(--gray-200);
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 20px;
    background-color: var(--red-100);
    border-color: var(--red-500);
    color: var(--red-700);

    &:hover {
      background-color: var(--gray-50);
      border-color: var(--gray-200);
      color: var(--gray-500);
    }
  }
}

:host([status="incomplete"].dragover) {
  .prompt-container {
    background-color: var(--gray-50);
    border-color: var(--gray-200);
    color: var(--gray-500);
  }
}

/* Component elements */
.upload-icon,
.files-icon {
  width: 2.5rem;
}

.upload-icon {
  display: flex;
}

input[type="file"] {
  display: none;
}

.message {
  line-height: 1.25rem;
  border-top: 1px solid #e5e7eb;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
}

.prompt {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 0.75rem;
}

.link {
  color: var(--green-500);
  &:hover {
    text-decoration: underline;
  }
}

.fail-message {
  font-weight: 500;
}

.prompt-container:not(:has(*)) {
  display: none;
}

:host([disabled]) {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
