/* eslint-disable lit-a11y/click-events-have-key-events */
import { LitElement, html, css, nothing } from "lit"
import { unsafeSVG } from "lit/directives/unsafe-svg.js"
import { unsafeHTML } from "lit/directives/unsafe-html.js"

import filesIcon from "../../../assets/images/icons/files.svg"

import styles from "../../styles/niva_document_input/upload_area.css"

import NivaFile from "./niva_file"

import "./file_item"

class UploadArea extends LitElement {
  static styles = css([styles])
  static properties = {
    t: { type: Object },
    status: { type: String, reflect: true },
    extraStatus: { type: String, state: true, reflect: false },
    disabled: { type: Boolean, reflect: true },
    failedAndSaved: { type: Boolean, reflect: true },
    file: { type: NivaFile },
  }

  updated(changedProps) {
    super.updated(changedProps)

    this.status = this.file.status
    this.failedAndSaved = this.file.isFailedWithId
    if (this.file.isEmpty) {
      this.renderRoot.querySelector("input[type='file']").value = ""
    }
  }

  render() {
    const showMessage =
      this.file?.status === "warning" || this.file?.isFailedWithId
    const canDelete = this.file.canDelete

    return html`
      <div
        @click=${this.#handleClick}
        @dragover=${this.#handleDragOver}
        @dragleave=${this.#handleDragLeave}
        @drop=${this.#handleDrop}
        class=${this.disabled ? "disabled" : ""}
      >
        <file-item
          .t=${this.t}
          .file=${this.file}
          .deleteDisabled=${!canDelete}
        ></file-item>

        <file-item
          .t=${this.t}
          .file=${this.file.extraFile}
          .deleteDisabled=${!canDelete}
        ></file-item>

        <div class="prompt-container">
          ${this.#renderPrompt()}
          ${this.#renderFailPrompt()}
        </div>

        <div
          class="message"
          .innerText=${this.file.message}
          ?hidden=${!showMessage}
        ></div>
      </div>

      <input type="file" hidden @change=${(e) => this.#uploadFile(e.target)}>
      </input>
    `
  }

  #renderPrompt() {
    if (!this.file.isEmpty || this.file.isFailed) return nothing

    return html`
      <div class="prompt">
        <div class="files-icon">${unsafeSVG(filesIcon)}</div>
        <div>${unsafeHTML(this.t.promptInstructions)}</div>
      </div>
    `
  }

  #renderFailPrompt() {
    if (
      !(
        ["fail", "error", "invalid"].includes(this.file.status) ||
        (this.file.isIncomplete && this.file.extraFile.isEmpty) ||
        (this.file.isIncomplete && this.file.extraFile.isInvalid)
      ) ||
      this.file.isFailedWithId
    )
      return nothing

    const message = this.file.extraFile.message || this.file.message
    return html`
      <div class="prompt">
        <div class="fail-message">${message}</div>
        <div class="upload-icon">${unsafeSVG(filesIcon)}</div>
        <div>${unsafeHTML(this.t.promptInstructions)}</div>
      </div>
    `
  }

  #handleDragOver(e) {
    e.preventDefault()
    if (!this.file.canUpload || this.disabled) return

    this.classList.add("dragover")
  }

  #handleDragLeave(e) {
    e.preventDefault()
    if (!this.file.canUpload || this.disabled) return

    this.classList.remove("dragover")
  }

  #handleDrop(e) {
    e.preventDefault()
    if (!this.file.canUpload || this.disabled) return

    this.classList.remove("dragover")
    this.#uploadFile(e.dataTransfer)
  }

  #handleClick(e) {
    e.stopPropagation()
    if (!this.file.canUpload || this.disabled) return

    this.renderRoot.querySelector("input[type='file']").click()
  }

  #uploadFile(target) {
    const event = new CustomEvent("upload-file", {
      detail: { file: target.files[0], key: this.file.key },
      bubbles: true,
    })
    this.dispatchEvent(event)
  }
}
customElements.define("upload-area", UploadArea)
