export default class NivaFile {
  static IN_PROGRESS_STATUSES = ["uploading", "validating"]
  static DELETABLE_STATUSES = [
    "warning",
    "incomplete",
    "valid",
    "validating",
    "fail",
  ]
  static UPLOADABLE_STATUSES = ["error", "fail", "incomplete", "invalid", null]
  static FAIL_STATUSES = ["error", "fail", "incomplete", "invalid"]
  static VALID_STATUSES = ["warning", "valid"]

  #status

  constructor(
    id,
    name,
    size,
    type,
    status,
    url,
    messageKey,
    message,
    extraFile,
    isExtra,
  ) {
    this.key = `${Date.now()}-${Math.random()}`
    if (!isExtra) extraFile.key = this.key

    this.id = id
    this.name = name
    this.size = size
    this.type = type
    this.status = status
    this.url = url
    this.messageKey = messageKey
    this.message = message
    this.extraFile = extraFile
    this.isExtra = isExtra
    this.rawFile = null
  }

  get container() {
    return this.status !== "incomplete" ? this : this.extraFile
  }

  get isUploadingExtra() {
    return this.isIncomplete && this.extraFile?.isInProgress
  }

  get canDelete() {
    return (
      NivaFile.DELETABLE_STATUSES.includes(this.status) &&
      !this.isUploadingExtra
    )
  }

  get canUpload() {
    return (
      NivaFile.UPLOADABLE_STATUSES.includes(this.status) &&
      !this.isFailedWithId &&
      !this.isUploadingExtra
    )
  }

  get isInProgress() {
    return NivaFile.IN_PROGRESS_STATUSES.includes(this.status)
  }

  get isEmpty() {
    return !this.name && !this.size
  }

  get isValid() {
    return NivaFile.VALID_STATUSES.includes(this.status)
  }

  get isFailed() {
    return NivaFile.FAIL_STATUSES.includes(this.status)
  }

  get isFailedWithId() {
    return this.id != null && this.status === "fail"
  }

  get isIncomplete() {
    return this.status === "incomplete"
  }

  get isInvalid() {
    return this.status === "invalid"
  }

  clone() {
    const newFile = new NivaFile(
      this.id,
      this.name,
      this.size,
      this.type,
      this.status,
      this.url,
      this.messageKey,
      this.message,
      this.extraFile?.clone(),
      this.isExtra,
    )
    newFile.key = this.key
    newFile.rawFile = this.rawFile
    return newFile
  }

  includesFile(file) {
    return this.rawFile === file || this.extraFile?.rawFile === file
  }

  updateFromFile(file) {
    this.container.name = file.name
    this.container.size = file.size
    this.container.type = file.type
    this.container.rawFile = file
  }

  updateFromDocument(doc) {
    if (!doc) return

    this.extraFile.clear()

    this.id = doc.id
    this.name = doc.name
    this.size = doc.size
    this.status = doc.status
    this.url = doc.url
    this.messageKey = doc.message_key
    this.message = doc.message
  }

  invalidate(message) {
    this.container.clear()

    this.container.status = "invalid"
    this.container.message = message
  }

  error(message) {
    this.container.clear()

    this.container.status = "error"
    this.container.message = message
  }

  clear() {
    this.name = null
    this.size = null
    this.type = null
    this.status = null
    this.url = null
  }

  static nullExtraFile() {
    return new NivaFile(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true,
    )
  }

  static nullFile() {
    const extraFile = NivaFile.nullExtraFile()
    return new NivaFile(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      extraFile,
      false,
    )
  }

  static fromDocument(doc) {
    const extraFile = NivaFile.nullExtraFile()
    return new NivaFile(
      doc.id,
      doc.name,
      doc.size,
      null,
      doc.status,
      doc.url,
      doc.message_key,
      doc.message,
      extraFile,
      false,
    )
  }
}
