import { DirectUpload } from "@rails/activestorage"

export default class FileService {
  #token = null

  constructor(input) {
    this.input = input
    this.t = input.t
    this.documentType = input.documentType
    this.apiKey = input.apiKey
    this.host = input.host
    this.validations = input.validations
    this.#token = input.token
  }

  get baseURL() {
    if (this.host) {
      return `${this.host}/v1`
    }
    const url = new URL(window.location.href)

    const hostParts = url.host.split(".")
    hostParts[0] = "api"

    const apiHost = hostParts.join(".")
    return `${window.location.protocol}//${apiHost}/v1`
  }

  get apiURL() {
    return `${this.baseURL}/internal/submissions/${this.#token}/documents`
  }

  get headers() {
    return {
      headers: {
        apiKey: this.apiKey,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": this.t.locale,
      },
    }
  }

  async getAll(callbacks) {
    const url = `${this.apiURL}?document_type=${this.documentType}`
    const response = await fetch(url, this.headers)

    if (response.ok) {
      callbacks.onSuccess?.(await response.json())
    } else {
      const errorData = await response.json()
      this.handleError(callbacks.onError, this.t.errors.fetchFailed, {
        errorData,
        response,
      })
    }
  }

  upload(file, callbacks) {
    const url = `${this.apiURL}/direct_upload`
    const upload = new DirectUpload(file, url, this, this.headers["headers"])

    upload.create((error, blob) => {
      if (error) {
        this.handleError(callbacks.onError, this.t.errors.uploadFailed)
      } else {
        callbacks.onSuccess?.(blob)
        this.validate(blob, file, callbacks)
      }
    })
  }

  async validate(blob, file, callbacks) {
    const response = await fetch(this.apiURL, {
      method: "POST",
      body: JSON.stringify({
        document_type: this.documentType,
        signed_blob_id: blob.signed_id,
        validation_inputs: this.validations,
      }),
      ...this.headers,
    })

    if (!response.ok) {
      const errorData = await response.json()
      this.handleError(callbacks.onError, this.t.errors.uploadFailed, {
        errorData,
        response,
      })
      return
    }

    const data = await response.json()
    if (this.input.files.every((nivaFile) => !nivaFile.includesFile(file))) {
      this.delete(data.id, {})
    } else {
      callbacks.onValidated?.(data, data.warning || "")
    }
  }

  async delete(id, callbacks) {
    if (!id) {
      callbacks.onSuccess?.()
      return
    }

    const url = `${this.apiURL}/${id}`
    const response = await fetch(url, { method: "DELETE", ...this.headers })

    if (!response.ok) {
      const errorData = await response.json()
      this.handleError(callbacks.onError, this.t.errors.removalFailed, {
        errorData,
        response,
      })
    } else {
      callbacks.onSuccess?.(response)
    }
  }

  handleError(callback, message, response = null) {
    const error = new Error(message)
    callback?.(error, response)
  }
}
