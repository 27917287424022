import { LitElement, css, html, nothing } from "lit"
import { repeat } from "lit/directives/repeat.js"

import "./niva_document_input/upload_area"
import NivaFile from "./niva_document_input/niva_file"
import FileService from "./niva_document_input/file_service"
import translations from "./niva_document_input/translations.json"

const boolAttributeConverter = {
  fromAttribute: (value, _) => {
    if (value === null) return false
    if (typeof value === "string") {
      return value.toLowerCase() === "true" || value === ""
    }
    return Boolean(value)
  },
  toAttribute: (value, _) => {
    if (value === null) return null
    if (typeof value === "boolean") {
      return value ? "true" : "false"
    }
    return String(value)
  },
}

export default class NivaDocumentInput extends LitElement {
  static formAssociated = true

  static styles = css`
    :host {
      border: none !important;
      background-color: transparent !important;
      padding: 0 !important;

      .add-more-link {
        display: block;
        margin: 0 auto;
        padding: 0.25rem 0;
        text-align: center;
        color: #6b7280;
        font-size: 0.75rem;
        text-decoration: none;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;
      }

      .add-more-link:hover {
        color: #4b5563;
      }

      .add-more-link::after {
        content: "▼";
        font-size: 0.6rem;
        margin-left: 4px;
        display: inline-block;
      }
    }
  `

  static hosts = {
    development: "http://api.niva.local:3000",
    test: "http://api.lvh.me:12096",
    staging: "https://api.niva-staging.com",
    demo: "https://api.niva-demo.com",
    production: "https://api.niva.co",
  }

  static properties = {
    documentType: { type: String },
    token: { type: String },
    multiple: { type: Boolean, converter: boolAttributeConverter },
    locale: { type: String },
    apiKey: { type: String },
    files: { type: Array, state: true, reflect: false },
    onSuccess: { type: Function },
    onError: { type: Function },
    disabled: {
      type: Boolean,
      reflect: true,
      converter: boolAttributeConverter,
    },
    validations: { type: Object },
  }

  #internals = this.attachInternals()
  #value = new Set()
  #t = null

  constructor() {
    super()
    this.files = [NivaFile.nullFile()]
  }

  get isDisabled() {
    return this.disabled
  }

  get isMultiple() {
    return this.multiple
  }

  get form() {
    return this.#internals.form
  }

  get t() {
    return this.#t
  }

  get value() {
    const valueArray = Array.from(this.#value)
    return this.isMultiple ? valueArray : valueArray[0] || ""
  }

  set value(newValue) {
    if (!newValue || newValue === "") return

    this.#value.add(newValue)

    this.#internals.setFormValue(this.value)
  }

  get host() {
    return NivaDocumentInput.hosts[process.env.RAILS_ENV || "development"]
  }

  get #hasUploadableArea() {
    return this.files.some((file) => file.canUpload)
  }

  get #uploadArea() {
    const selector = "upload-area:not([status]):last-of-type"
    return this.renderRoot.querySelector(selector)
  }

  checkValidity() {
    if (this.hasAttribute("required") && this.#value.size === 0) {
      this.#internals.setValidity(
        { valueMissing: true },
        this.t.errors.required,
      )
    } else {
      this.#internals.setValidity({}, "")
    }

    return this.#internals.checkValidity()
  }

  setError(message) {
    const uploadAreaFile = this.files.find((file) => file.canUpload)
    this.changeFile(uploadAreaFile?.key, (f) => f.invalidate(message))
  }

  connectedCallback() {
    super.connectedCallback()

    this.locale = this.locale || "en"
    this.#t = translations[this.locale]

    this.fileService = new FileService(this)

    this.fileService.getAll({
      onSuccess: this.#setFiles,
      onError: (error, response) => {
        this.#dispatchEvent("error", {
          key: "initialization_failed",
          message: this.t.errors.fetchFailed,
          error: error.message,
          response: response,
        })
      },
    })
  }

  render() {
    return html`
      ${repeat(this.files, (file) => file.key, this.#renderUploadArea)}
      ${this.#renderAddMoreLink()}
    `
  }

  #setFiles = (docs) => {
    if (!Array.isArray(docs) || docs.length === 0) return

    this.files = docs.map(NivaFile.fromDocument)
    this.files
      .filter((file) => file.isValid)
      .forEach((file) => (this.value = file.id))

    this.requestUpdate()

    this.#dispatchEvent("widget-initialized", {
      documentType: this.documentType,
      files: this.files
        .map((file) => this.#fileToPayload(file))
        .filter((file) => file.id),
    })
  }

  #renderUploadArea = (file, _index) => {
    return html`
      <upload-area
        .t=${this.t}
        .file=${file}
        ?disabled=${this.isDisabled}
        tabindex=${file.canUpload && !this.isDisabled && "0"}
        @upload-file=${this.#uploadFile}
        @delete-file=${this.#deleteFile}
      ></upload-area>
    `
  }

  #renderAddMoreLink() {
    if (!this.isMultiple || this.#hasUploadableArea || this.isDisabled) {
      return nothing
    }

    return html`
      <span
        class="add-more-link"
        tabindex="0"
        @click=${this.#addUploadArea}
        @keydown=${this.#handleUploadAreaKeydown}
      >
        ${this.t.addMore}
      </span>
    `
  }

  #handleUploadAreaKeydown(e) {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault()

      this.#addUploadArea()
    }
  }

  #addUploadArea = () => {
    if (!this.#hasUploadableArea) {
      this.files.push(NivaFile.nullFile())
    }

    this.requestUpdate()
  }

  #uploadFile = (e) => {
    e.stopPropagation()
    if (this.isDisabled) return

    const { file, key } = e.detail
    if (!file) return

    this.changeFile(key, (f) => f.updateFromFile(file))

    if (!this.#validate(key, file)) return

    this.changeFile(key, (f) => (f.container.status = "uploading"))

    this.#dispatchEvent("upload-started", {
      file: this.#fileToPayload(file),
    })

    this.fileService.upload(file, {
      onSuccess: (_blob) => {
        this.changeFile(key, (f) => (f.container.status = "validating"))
      },
      onError: (error, response) => {
        this.changeFile(key, (f) => f.error(error))
        this.#dispatchEvent("error", {
          key: "upload_error",
          message: this.t.errors.uploadFailed,
          error: error.message,
          file: this.#fileToPayload(this.files.find((f) => f.key === key)),
          response: response,
        })
      },
      onValidated: (doc, _warning) => {
        this.changeFile(key, (f) => f.updateFromDocument(doc))
        this.value = doc.id

        // For "incomplete" or "fail" status, dispatch error event
        if (["incomplete", "fail"].includes(doc.status)) {
          const errorKey =
            doc.status === "incomplete"
              ? "document_incomplete"
              : "document_validation_failed"

          this.#dispatchEvent("error", {
            key: errorKey,
            message: doc.message || this.t.errors.uploadFailed,
            file: this.#fileToPayload(this.files.find((f) => f.key === key)),
          })
          return
        }

        // For "valid" or "warning" status, dispatch success event
        if (["valid", "warning"].includes(doc.status)) {
          this.#dispatchEvent("success", {
            file: this.#fileToPayload(this.files.find((f) => f.key === key)),
          })
        }
      },
    })
  }

  #validate = (key, file) => {
    const maxFileSize = 50 * 1024 * 1024 // 50 MB
    if (file.size > maxFileSize) {
      const message = this.t.errors.fileSizeTooBig
      file.status = "invalid"
      file.message = message
      file.messageKey = "file_size_too_big"
      this.changeFile(key, (f) => f.invalidate(message))
      this.requestUpdate()
      this.#internals.setValidity({ customError: true }, message)

      this.#dispatchEvent("error", {
        key: "document_validation_failed",
        message: message,
        file: this.#fileToPayload(file),
      })

      return false
    }

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]
    if (!allowedTypes.includes(file.type)) {
      const message = this.t.errors.unsupportedFileType
      file.status = "invalid"
      file.message = message
      file.messageKey = "unsupported_file_type"
      this.changeFile(key, (f) => f.invalidate(message))
      this.requestUpdate()
      this.#internals.setValidity({ customError: true }, message)

      this.#dispatchEvent("error", {
        key: "document_validation_failed",
        message: message,
        file: this.#fileToPayload(file),
      })

      return false
    }

    return true
  }

  changeFile(key, changeFunc) {
    this.files = this.files.map((f) => {
      if (f.key === key) {
        const newFile = f.clone()
        changeFunc(newFile)
        return newFile
      }

      return f
    })
  }

  #deleteFile = (e) => {
    e.stopPropagation()
    if (this.isDisabled) return

    const { key } = e.detail
    const fileToDelete = this.files.find((f) => f.key === key)
    const documentId = fileToDelete?.id

    this.#value.delete(documentId)
    this.#internals.setFormValue(this.value)

    this.files = this.files.filter((f) => f.key !== key)
    this.#addUploadArea()

    if (documentId) {
      this.fileService.delete(documentId, {
        onSuccess: (_response) => {
          this.#dispatchEvent("delete-success", {
            key: "deletion_success",
            file: this.#fileToPayload(fileToDelete),
          })
        },
        onError: (error, response) => {
          const key = this.files.find((f) => f.canUpload)?.key
          this.changeFile(key, (f) => f.error(this.t.errors.removalFailed))

          this.#dispatchEvent("error", {
            key: "deletion_failed",
            message: this.t.errors.removalFailed,
            error: error.message,
            file: this.#fileToPayload(fileToDelete),
            response: response,
          })
        },
      })
    }
  }

  #dispatchEvent(eventName, detail) {
    if (detail?.response?.response?.status === 401) {
      const errorMessage = detail.response.errorData?.errors?.[0]
      if (errorMessage?.includes("Unauthorized")) {
        eventName = "invalid_api_key"
      } else if (errorMessage?.includes("Invalid token")) {
        eventName = "invalid_token"
      }
    }
    const event = new CustomEvent(eventName, {
      bubbles: true,
      composed: true,
      detail: detail,
    })
    this.dispatchEvent(event)

    // Call a callback if provided
    if (eventName === "success" && typeof this.onSuccess === "function") {
      this.onSuccess(detail)
    } else if (eventName === "error" && typeof this.onError === "function") {
      this.onError(detail)
    }
  }

  #fileToPayload(file) {
    if (!file) return {}

    return {
      id: file.id,
      name: file.name,
      size: file.size,
      type: file.type,
      status: file.status,
      url: file.url,
      validation_error: file.messageKey
        ? {
            error: file.messageKey,
            error_message: file.message,
          }
        : null,
    }
  }
}
