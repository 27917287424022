:host {
  --blue-100: rgb(219 234 254);
  --blue-800: rgb(30 64 175);
  --emerald-100: rgb(209 250 229);
  --emerald-700: rgb(4 120 87);
  --green-500: rgb(14 195 80);
  --gray-500: rgb(107 114 128);
  --gray-800: rgb(31 41 55);
  --yellow-100: rgb(254 249 195);
  --yellow-800: rgb(133 77 14);
  --red-100: rgb(254 226 226);
  --red-300: rgb(252, 165, 165);
  --red-500: rgb(239 68 68);
  --red-800: rgb(185 28 28);
  --red-900: rgb(127, 29, 29);
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.cancel {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.cancel:hover {
  filter: brightness(0.5);
}

.file-info {
  flex: 1 1 auto;
  align-items: center;
  column-gap: 0.75rem;
}

a {
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: underline;
  }
}

.name {
  font-weight: 500;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12rem;
  color: var(--gray-800);
  margin: 0;
}

.size {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-500);
  margin: 0;
}

.upload-info {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  min-width: 0;
  flex: 0 0 auto;
}

.progress[status="warning"],
.progress[status="incomplete"] {
  background-color: var(--yellow-100);
  color: var(--yellow-800);
}

.progress[status="uploading"] {
  background-color: var(--blue-100);
  color: var(--blue-800);
}

.progress[status="validating"] {
  background-color: var(--blue-100);
  color: var(--blue-800);
}

.progress[status="fail"] {
  background-color: var(--red-300);
  color: var(--red-900);
}

.progress[status="valid"] {
  background-color: var(--emerald-100);
  color: var(--emerald-700);
}

.icon {
  display: flex;
  align-items: center;
  svg {
    height: 1em;
  }

  svg.spinner {
    height: 1.5em;
  }
}

.progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  min-width: 7rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  column-gap: 0.5rem;
}

@media (max-width: 390px) {
  .progress {
    min-width: 5rem;
  }
}

.spinner {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
